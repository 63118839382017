import { Col, Modal, Row, Card, Checkbox, Button, message as alert } from "antd"
import { LayoutComponent as Layout } from "../../components"
import { getPersonal, getPersonalAuth0 } from "../../redux/slices/personalsSlice"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { Navigate, useParams } from "react-router-dom"
import moment from "moment"
import user from '../../assets/img/user.png'
import { getSpecialties } from "../../redux/slices/specialtiesSlice"
import { getLanguages } from "../../redux/slices/languagesSlice"
import { getGyms } from "../../redux/slices/gymsSlice"
import { EditOutlined } from "@ant-design/icons"
import { createPersonal } from "../../redux/slices/personalsSlice"

const Professional = () => {

    const dispatch = useDispatch()
    const { isLoading, personal, authId, isSubmit } = useSelector(state => state.personals)
    const { languages } = useSelector(state => state.languages)
    const { gyms } = useSelector(state => state.gyms)
    const { specialties } = useSelector(state => state.specialties)
    const { id } = useParams()

    const [selectedLanguages, setSelectedLanguages] = useState([])
    const [selectedSpecialties, setSelectedSpecialties] = useState([])
    const [selectedGyms, setSelectedGyms] = useState([])

    const [modalLanguage, setModalLanguage] = useState(false)
    const [modalSpecialty, setModalSpecialty] = useState(false)
    const [modalGyms, setModalGyms] = useState(false)

    const genders = { FEMALE: 'Feminino', MALE: 'Masculino' }

    useEffect(() => {
        dispatch(getPersonal(id))
        dispatch(getSpecialties())
        dispatch(getLanguages())
        dispatch(getGyms())
    }, [])

    useEffect(() => {
        if (authId === '') {
            dispatch(getPersonalAuth0({ email: personal.email })).then(res => console.log(res))
        }
    }, [authId])

    useEffect(() => {
        if (!isLoading) {
            if (personal.locals) {
                setSelectedGyms(personal.locals.length > 0 ? [...personal.locals] : [])
            }
            if (personal.languages) {
                setSelectedLanguages(personal.languages.length > 0 ? [...personal.languages] : [])
            }
            if (personal.specialties) {
                setSelectedSpecialties(personal.specialties.length > 0 ? [...personal.specialties] : [])
            }
        }
    }, [isLoading, personal])

    const handleCheck = (item, type) => {
        if (type === "LANGUAGE") {
            const copy = [...selectedLanguages]
            let index = -1
            for (var i = 0; i < copy.length; i++) {
                if (copy[i]['name'] === item.name) {
                    index = i
                }
            }
            if (index === -1) {
                copy.push(item)
            } else {
                copy.splice(index, 1)
            }
            setSelectedLanguages([...copy])
        }
        if (type === "GYM") {
            const copy = [...selectedGyms]
            let index = -1
            for (var i = 0; i < copy.length; i++) {
                if (copy[i]['name'] === item.name) {
                    index = i
                }
            }
            if (index === -1) {
                copy.push(item)
            } else {
                copy.splice(index, 1)
            }
            setSelectedGyms([...copy])
        }
        if (type === "SPECIALTY") {
            const copy = [...selectedSpecialties]
            let index = -1
            for (var i = 0; i < copy.length; i++) {
                if (copy[i]['name'] === item.name) {
                    index = i
                }
            }
            if (index === -1) {
                copy.push(item)
            } else {
                copy.splice(index, 1)
            }
            setSelectedSpecialties([...copy])
        }
    }

    const onSubmit = () => {
        const formData = {
            ...personal,
            locals: selectedGyms,
            specialties: selectedSpecialties,
            languages: selectedLanguages
        }

        if (selectedLanguages.length === 0) {
            return alert.error('Selecione um idioma')
        }
        if (selectedSpecialties.length === 0) {
            return alert.error('Selecione uma especialidade')
        }
        dispatch(createPersonal({ ...formData, authId })).then(res => {
            console.log(res)
            if (res.meta.requestStatus === 'fulfilled') {
                alert.success('Profissional atualizado com sucesso')
                setModalGyms(false)
                setModalLanguage(false)
                setModalSpecialty(false)
                dispatch(getPersonal(id))

            } else {
                alert.error('Ops, houve um erro ao criar o profissional.')
            }
        }).catch(err => {
            alert.error('Ops, houve um erro ao criar o profissional.');
        })
    }

    const returnLanguages = () => {
        const lenght = personal.languages.length
        if (lenght === 1) {
            return personal.languages[0].name
        }
        if (lenght >= 2) {
            const diff = lenght - 2
            if (diff > 0) {
                return personal.languages[0].name + ', ' + personal.languages[1].name + ", +" + diff
            } else {
                return personal.languages[0].name + ', ' + personal.languages[1].name
            }
        }
    }

    const returnGyms = () => {
        const lenght = personal.locals.length
        if (lenght === 1) {
            return personal.locals[0].name
        }
        if (lenght >= 2) {
            const diff = lenght - 2
            if (diff > 0) {
                return personal.locals[0].name + ', ' + personal.locals[1].name + ", +" + diff
            } else {
                return personal.locals[0].name + ', ' + personal.locals[1].name
            }
        }
    }

    const returnSpecialties = () => {
        const lenght = personal.specialties.length
        if (lenght === 1) {
            return personal.specialties[0].name
        }
        if (lenght >= 2) {
            const diff = lenght - 2
            if (diff > 0) {
                return personal.specialties[0].name + ', ' + personal.specialties[1].name + ", +" + diff
            } else {
                return personal.specialties[0].name + ', ' + personal.specialties[1].name
            }
        }
    }

    return (
        <Layout>
            {isLoading ? 'Carregando' : !personal ? <Navigate to="/profissinais" /> : (
                <Row>
                    <Col md={8}>
                        <div className="wrapper-professional">
                            <div className="d-flex">
                                <img className="img-personal" src={personal.photo || user} alt="Imagem personal" />
                                <div className="id-wrapper">
                                    <span className="tx-gray">ID: {id}</span>
                                    <h3>{personal.firstName} {personal.lastName}</h3>
                                </div>
                            </div>
                            <div className="d-flex mt-50 w-100 justify-between">
                                <span><b>CREF:</b> {personal.cref}</span>
                                <span><b>CPF:</b> {personal.cpf}</span>
                            </div>
                            <div className="d-flex w-100 mt-50">
                                <h4 className="w-40 tx-bold">E-mail</h4>
                                <span>{personal.email}</span>
                            </div>
                            <div className="d-flex w-100">
                                <h4 className="w-40 tx-bold">Gênero</h4>
                                <span>{genders[personal.gender]}</span>
                            </div>
                            <div className="d-flex w-100">
                                <h4 className="w-40 tx-bold">Nascimento</h4>
                                <span>{moment(personal.birthDate).format("DD/MM/YYYY")}</span>
                            </div>
                            <hr />
                            <div className="d-flex w-100">
                                <h4 className="w-40 tx-bold">Idiomas</h4>
                                <span>
                                    {personal.languages && personal.languages.length > 0 ?
                                        returnLanguages() : 'Nenhum idioma cadastrado'
                                    }
                                </span>
                                <EditOutlined onClick={() => setModalLanguage(true)} />
                            </div>
                            <hr />
                            <div className="d-flex w-100">
                                <h4 className="w-40 tx-bold">Academias</h4>
                                <span>
                                    {personal.locals && personal.locals.length > 0 ?
                                        returnGyms() : 'Nenhuma academia cadastrada'
                                    }
                                </span>
                                <EditOutlined onClick={() => setModalGyms(true)} />
                            </div>
                            <hr />
                            <div className="d-flex w-100">
                                <h4 className="w-40 tx-bold">Especialidades</h4>
                                <span>
                                    {personal.specialties && personal.specialties.length > 0 ?
                                        returnSpecialties() : 'Nenhuma especialidade cadastrada'
                                    }
                                </span>
                                <EditOutlined onClick={() => setModalSpecialty(true)} />
                            </div>
                        </div>
                    </Col>
                    <Col md={16}></Col>
                </Row>
            )}
            <Modal
                open={modalLanguage}
                onCancel={() => setModalLanguage(false)}
                title="Idiomas"
                maskClosable={false}
                footer={''}
            >
                <div className="lang-wrapper">
                    {languages && languages.length > 0 ?
                        languages.map(lang => (
                            <Card className="mt-20">
                                <div className="d-flex">
                                    <Checkbox onChange={() => handleCheck(lang, 'LANGUAGE')} checked={selectedLanguages.length > 0 && selectedLanguages.some(item => JSON.stringify(item) === JSON.stringify(lang))}>
                                        <span>{lang.name}</span>
                                    </Checkbox>
                                </div>
                            </Card>
                        )) : 'Nenhum idioma cadastrado'
                    }
                </div>
                <div className='flex-center mt-10'>
                    <Button onClick={() => {
                        setModalLanguage(false)
                        setSelectedLanguages([...personal.languages])
                    }}>Cancelar</Button>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='ml-20'
                        onClick={onSubmit}
                        loading={isSubmit}
                    >
                        Salvar
                    </Button>
                </div>
            </Modal>
            <Modal
                open={modalSpecialty}
                onCancel={() => setModalSpecialty(false)}
                title="Especialidades"
                maskClosable={false}
                footer={''}
            >
                <div className="lang-wrapper">
                    {specialties && specialties.length > 0 ?
                        specialties.map(specialty => (
                            <Card className="mt-20">
                                <div className="d-flex">
                                    <Checkbox onChange={() => handleCheck(specialty, 'SPECIALTY')} checked={selectedSpecialties.length > 0 && selectedSpecialties.some(item => JSON.stringify(item) === JSON.stringify(specialty))}>
                                        <img src={specialty.picture} alt={`Imagem de ${specialty.name}`} className="img-specialty" />
                                        <span>{specialty.name}</span>
                                    </Checkbox>
                                </div>
                            </Card>
                        )) : 'Nenhum idioma cadastrado'
                    }
                </div>
                <div className='flex-center mt-10'>
                    <Button onClick={() => {
                        setModalSpecialty(false)
                        setSelectedSpecialties([...personal.specialties])
                    }}>Cancelar</Button>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='ml-20'
                        onClick={onSubmit}
                        loading={isSubmit}
                    >
                        Salvar
                    </Button>
                </div>
            </Modal>
            <Modal
                open={modalGyms}
                onCancel={() => setModalGyms(false)}
                title="Academias"
                maskClosable={false}
                footer={''}
            >
                <div className="lang-wrapper">
                    {gyms && gyms.length > 0 ?
                        gyms.map(gym => (
                            <Card className="mt-20">
                                <div className="d-flex">
                                    <Checkbox onChange={() => handleCheck(gym, 'GYM')} checked={selectedGyms.length > 0 && selectedGyms.some(item => JSON.stringify(item) === JSON.stringify(gym))}>
                                        <span>{gym.name}</span><br />
                                        <small>{gym.street}</small>
                                    </Checkbox>
                                </div>
                            </Card>
                        )) : 'Nenhum idioma cadastrado'
                    }
                </div>
                <div className='flex-center mt-10'>
                    <Button onClick={() => {
                        setModalGyms(false)
                        setSelectedGyms([...personal.locals])
                    }}>Cancelar</Button>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='ml-20'
                        onClick={onSubmit}
                        loading={isSubmit}
                    >
                        Salvar
                    </Button>
                </div>
            </Modal>
        </Layout>
    )
}

export default Professional